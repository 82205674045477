import { useRef } from "react";
import classnames from "classnames";
import DefaultSelect from "../DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { getBlockImg } from "@ugg/shared/utils/region-helpers";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface RegionProps extends FilterSelect {
  className?: string;
  options: FilterOption[];
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function Region(props: RegionProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();
  const { className, isMobile, options, filters, isDefault, onFilterChange, disableTooltip } = props;

  const formatOptionLabel = ({ value, label, flag, img }: (typeof options)[number]) => (
    <div className={`region-option`} key={value}>
      {value === "world" ? (
        <div className="region-img-wrapper">
          <img className={`region-img ${flag ? "flag-img" : ""}`} src={img as string} />
        </div>
      ) : (
        <img src={getBlockImg(value)} />
      )}
      <span style={{ marginLeft: "10px" }}>{t(label)}</span>
    </div>
  );

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Region")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Region")}
          group={"region"}
          isMobile={isMobile}
          className={classnames("filter-select", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]: typeof isDefault === "boolean" ? !isDefault : !isDefault?.("region"),
          })}
          options={options}
          onChange={(selected: (typeof options)[number]) => {
            onFilterChange("region", selected);
          }}
          value={filters.region || filters.regionId}
          formatOptionLabel={formatOptionLabel}
          width={120}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
