import { useState } from "react";
import Switch from "react-switch";
import Modal from "../../common/Modal";
import FilterModal from "../FilterModal";
import { getTierListPickRateCutoff } from "@ugg/shared/utils/tier-helpers";
import { FilterSelect } from "@ugg/shared/interfaces/filter-manager.interface";

interface ShowAllProps extends FilterSelect {
  isMobile?: boolean;
}

export default function ShowAll(props: ShowAllProps) {
  const { isMobile, filters, onFilterChange } = props;
  const [showAll, setShowAll] = useState(filters.allChampions === "true" ? true : false);

  const onChangeHandler = () => {
    setShowAll(!showAll);
  };

  const onCancel = () => {
    setShowAll(filters.allChampions === "true" ? true : false);
  };

  const onApply = () => {
    if (filters.allChampions !== `${showAll}`) {
      onFilterChange("allChampions", {
        value: `${showAll}`,
        label: `${showAll}`,
      });
    }
  };

  const button = <div className={`filter-select filter-select_show-all ${showAll ? "active" : ""}`}>Show All</div>;

  const SwitchComponent = (additionalProps: Record<string, any>) => (
    <Switch
      width={72}
      height={36}
      handleDiameter={30}
      onColor={"#222238"}
      offColor={"#222238"}
      onHandleColor={"#08A6FF"}
      offHandleColor={"#FF4E50"}
      uncheckedIcon={false}
      checkedIcon={false}
      onChange={onChangeHandler}
      checked={showAll}
      {...additionalProps}
    />
  );

  if (isMobile) {
    return (
      <FilterModal button={button} title={"Show All Champions"} onApply={onApply} onCancel={onCancel}>
        <div className="filter-modal_content__show-all">
          <div className="switch-row">
            <div>Don't show</div>
            <SwitchComponent className="switch-container" />
            <div>Show</div>
          </div>
          <div className="filter-description">
            This option allows you to display champions with pick rates less than {getTierListPickRateCutoff()}%.
          </div>
        </div>
      </FilterModal>
    );
  }

  return (
    <Modal
      dataTip={"Show All Champions"}
      modalClassNames={"select_modal__modal-window__filter"}
      button={button}
      onApply={onApply}
      onCancel={onCancel}
    >
      <div className="modal_toggle">
        <div className="filter-description">
          <div className="title">Show All Champions</div>
          <div className="sub-text">
            This option allows you to display champions with pick rates less than {getTierListPickRateCutoff()}%.
          </div>
        </div>
        <SwitchComponent />
      </div>
    </Modal>
  );
}
