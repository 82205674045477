import classNames from "classnames";

export function HeaderPatch(props: { className?: string; patch: number | string }) {
  const { className, patch } = props;

  return (
    <div
      className={classNames(
        `
        inline-flex items-center justify-center h-[36px]
        px-[12px] rounded-[3px] bg-purple-400 
        text-white font-["Inter"] text-[14px] font-semibold
      `,
        className,
      )}
    >
      Patch {patch}
    </div>
  );
}
