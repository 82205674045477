import { window } from "global";
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";

interface ModalProps {
  children: React.ReactNode;
  style?: object;
  classNames?: string;
  toggleModalOpen: (bool: boolean) => any;
  onCancel?: Function;
  onApply?: Function;
  bDisableFooter?: boolean;
}

const Modal = React.forwardRef<unknown, ModalProps>((props, ref) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { style, classNames, toggleModalOpen, onCancel, onApply, bDisableFooter } = props;

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (modalRef?.current && e.target !== null && !modalRef.current.contains(e.target as HTMLElement)) {
        onApplyHandler();
      }
    };
    if (window) {
      document.addEventListener("click", handleOutsideClick, true);

      return () => document.removeEventListener("click", handleOutsideClick, true);
    }
  }, []);

  const onCancelHandler = () => {
    onCancel && onCancel();
    toggleModalOpen(false);
  };

  const onApplyHandler = () => {
    onApply && onApply();
    toggleModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    onCancel: onCancelHandler,
    onApply: onApplyHandler,
  }));

  return (
    <div className={classnames("select_modal__modal-window", classNames)} ref={modalRef} style={style}>
      <div>{props.children}</div>
      {!bDisableFooter && (
        <div className="selection-footer">
          <div className="clear" onClick={onCancelHandler} style={{ fontWeight: "normal" }}>
            Cancel
          </div>
          <div className="apply" onClick={onApplyHandler}>
            APPLY
          </div>
        </div>
      )}
    </div>
  );
});

interface ModalContainerProps {
  children: React.ReactNode;
  dataTip?: any;
  disableTooltip?: boolean;
  modalContainerClassNames?: string;
  buttonClassNames?: string;
  buttonStyle?: object;
  button?: any;
  modalClassNames?: string;
  modalStyle?: object;
  onApply?: Function;
  onCancel?: Function;
  bDisableFooter?: boolean;
  toggleOpen?: Function;
  bModalOpen?: boolean;
}

const ModalContainer = React.forwardRef<unknown, ModalContainerProps>((props, ref) => {
  const modalTooltip = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const [modalOpen, setModalOpen] = useState(false);
  const {
    dataTip,
    disableTooltip,
    modalContainerClassNames,
    buttonClassNames,
    buttonStyle,
    button,
    modalClassNames,
    modalStyle,
    onApply,
    onCancel,
    bDisableFooter,
    toggleOpen,
    bModalOpen,
  } = props;

  const toggleModalOpen = (toggle = !modalOpen) => {
    if (toggle) {
      modalTooltip.current?.forceClose();
    }

    toggleOpen ? toggleOpen() : setModalOpen(toggle);
  };

  const isModalOpen = (bModalOpen !== undefined && bModalOpen !== false) || modalOpen;
  const containerClassNames = classnames("modal-filter", modalContainerClassNames, {
    "modal-filter__open": isModalOpen,
  });

  return (
    <div className={containerClassNames}>
      <div className="select_modal">
        {button && (
          <TooltipContainer ref={modalTooltip} tooltipInfo={dataTip} disableTooltip={disableTooltip}>
            <div
              className={classnames("select_modal__toggle", buttonClassNames)}
              onClick={() => toggleModalOpen()}
              style={buttonStyle}
            >
              {button}
            </div>
          </TooltipContainer>
        )}
        {isModalOpen && (
          <Modal
            ref={ref}
            toggleModalOpen={toggleModalOpen}
            classNames={modalClassNames}
            style={modalStyle}
            onApply={onApply}
            onCancel={onCancel}
            bDisableFooter={bDisableFooter}
          >
            {props.children}
          </Modal>
        )}
      </div>
    </div>
  );
});

export default ModalContainer;
