import { useRef } from "react";
import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import DefaultSelect from "../DefaultSelect";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface PatchProps extends FilterSelect {
  className?: string;
  options: FilterOption[];
  isDefaultPatch?: boolean;
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function Patch(props: PatchProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();
  const { className, isMobile, filters, isDefault, isDefaultPatch, onFilterChange, disableTooltip, options } = props;

  const formatOptionLabel = ({ value, label }: (typeof options)[number]) => {
    return (
      <div className="Select-value">
        <span className="Select-value-label">{value === "all" ? "All" : label}</span>
      </div>
    );
  };

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Patch")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Patch")}
          group={"patch"}
          isMobile={isMobile}
          className={classnames("filter-select patch", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]:
              !isDefaultPatch && (typeof isDefault === "boolean" ? !isDefault : !isDefault?.("patch")),
          })}
          options={options}
          isSearchable={false}
          onChange={(selected: (typeof options)[number]) => onFilterChange("patch", selected)}
          value={filters.patch}
          formatOptionLabel={formatOptionLabel}
          width={80}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
