import { useMemo } from "react";
import { useUGGPatches } from "../../../api/requests/patches";
import { PatchOption } from "../../../interfaces/filter-manager.interface";

export function usePatchOptions(patchOption: PatchOption, currentPatch: string, defaultPatch?: string) {
  const { data: uggPatches } = useUGGPatches();

  return useMemo(() => {
    const formatPatchOptions = (list: string[]) =>
      list.map((el) => ({
        value: el,
        label: el.replace("_", "."),
      }));

    let patchOptions: Array<{ value: string; label: string }> = [];
    let isDefaultPatch = false;
    if (patchOption) {
      if (patchOption.options === "latest") {
        patchOptions = formatPatchOptions((uggPatches || []).slice(0, patchOption.max));
        isDefaultPatch = currentPatch === uggPatches?.[0];
      } else if (Array.isArray(patchOption.options)) {
        patchOptions = formatPatchOptions(patchOption.options.map((option) => option.value));
        isDefaultPatch = currentPatch === defaultPatch;
      }
    }

    return { options: patchOptions, isDefaultPatch };
  }, [JSON.stringify(patchOption), currentPatch, defaultPatch]);
}
