import { useRef } from "react";
import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import DefaultSelect from "../DefaultSelect";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface RankProps extends FilterSelect {
  className?: string;
  options: FilterOption[];
  disableByQueueType?: boolean;
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function Rank(props: RankProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();
  const { className, isMobile, options, filters, isDefault, onFilterChange, disableTooltip } = props;

  const formatOptionLabel = ({ value, label, img }: (typeof options)[number]) => {
    return (
      <div className="rank-option">
        <img
          className="rank-img"
          style={value === "overall" ? { width: "18px", marginLeft: 1 } : {}}
          src={img as string}
          alt={label}
        />
        <span style={{ marginLeft: "10px" }}>{t(label)}</span>
      </div>
    );
  };

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Rank")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Rank")}
          group={"rank"}
          isMobile={isMobile}
          className={classnames("filter-select filter-select_rank", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]: typeof isDefault === "boolean" ? !isDefault : !isDefault?.("rank"),
          })}
          options={options}
          onChange={(selected: (typeof options)[number]) => {
            onFilterChange("rank", selected);
          }}
          value={filters.rank || filters.mmr}
          formatOptionLabel={formatOptionLabel}
          width={170}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
